// eslint-disable-next-line no-undef
window._config = {
  baseUrl: 'https://uat.login.bandwidth.com',
  dashboard: 'https://test.dashboard.bandwidth.com',
  irisApi: 'https://test.dashboard.bandwidth.com/api/v2',
  oneIdManagementApi: 'https://uat.management.id.bandwidth.com',
  oidc: {
    issuer: 'https://uat.login.bandwidth.com/oauth2/ausr351h00uooRsos0h7',
    clientId: '0oavz8mdhfRCo3NBk0h7',
    redirectUri: 'https://uat.passport.bandwidth.com/login',
    postLogoutRedirectUri: 'https://uat.passport.bandwidth.com/logout',
    scopes: ['openid', 'oneid', 'email', 'profile'],
    pkce: true,
    storageManager: {
      token: {
        storageTypes: ['sessionStorage'],
      },
    },
  },
  cookies: {
    secure: true,
  },
  datadog: {
    env: 'uat',
    applicationId: 'a8c3461d-e18a-4539-9b0f-4f4833918058',
    clientToken: 'pub81d618dcf76d9080292c09d3a983fe21',
  },
  allowedReturnToUrls: [
    'test.dashboard.bandwidth.com',
    'uat.accounts.bandwidth.com',
    'numbers-history-app.test.dashboard.bandwidth.com',
    'uat.app.bandwidth.com',
  ],
};
